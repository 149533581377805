@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    margin: 0;
    padding: 0;
    height: 100vh;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: var(--text-primary);
}

button {
    font-family: "Poppins", sans-serif;
}

@layer base {
    :root {
        --white: #ffffff;
        --black: #000000;
        --content-width: 78rem;

        /* 
            Color Primitives. Only used primitives are defined here. See Figma for full list. 
        */
        --brand-50: #ffe8e1;
        --brand-100: #ffc2b4;
        --brand-300: #f57557;
        --brand-400: #f04b24;
        --brand-500: #de3719;
        --brand-600: #a8280a;

        --brand-b-50: #e1f8fa;
        --brand-b-500: #008c95;
        --brand-b-600: #007c83;
        --brand-b-700: #00686a;
        --brand-b-800: #00484e;

        --brand-c-300: #5ad2fc;
        --brand-c-400: #3bc6fa;
        --brand-c-500: #2eade1;
        --brand-c-700: #11607e;
        --brand-c-800: #003e52;
        --brand-c-800-alpha: rgb(0, 62, 82, 0.4);
        --brand-c-900: #00141d;

        --system-green-100: #dcfce7;
        --system-green-300: #86efac;
        --system-green-600: #16a34a;
        --system-green-700: #15803d;
        --system-green-900: #14532d;

        --system-red-300: #fca5a5;
        --system-red-400: #f87171;
        --system-red-500: #ef4444;
        --system-red-600: #dc2626;
        --system-red-700: #b91c1c;

        --gray-50: #f9fafb;
        --gray-100: #f3f4f6;
        --gray-200: #e5e7eb;
        --gray-300: #d1d5db;
        --gray-400: #9ca3af;
        --gray-500: #6b7280;
        --gray-600: #4b5563;
        --gray-700: #374151;

        /* 
            Tokens. Only used tokens are defined here. See Figma for full list. 
        */
        /* Text */
        --text-primary: var(--brand-c-800);
        --text-primary-invert: var(--white);
        --text-secondary: var(--gray-600);
        --text-brand: var(--brand-500);
        --text-brand-b: var(--brand-b-600);
        --text-brand-secondary: var(--brand-600);
        --text-success: var(--system-green-700);
        --text-danger: var(--system-red-700);

        /* Border */
        --border-primary: var(--gray-300);
        --border-secondary: var(--gray-200);
        --border-secondary-invert: var(--brand-c-700);
        --border-tertiary: var(--gray-400);
        --border-brand: var(--brand-400);
        --border-brand-b: var(--brand-b-500);
        --border-danger: var(--system-red-600);

        /* Background */
        --bg-primary: var(--white);
        --bg-primary-invert: var(--brand-c-800);
        --bg-secondary: var(--gray-100);
        --bg-tertiary: var(--gray-200);
        --bg-quaternary: var(--gray-50);
        --bg-overlay: var(--brand-c-800-alpha);
        --bg-brand-weakest: var(--brand-50);
        --bg-brand-primary: var(--brand-400);
        --bg-brand-secondary: var(--brand-500);
        --bg-brand-tertiary: var(--brand-600);
        --bg-brand-quaternary: var(--brand-100);
        --bg-brand-b-primary: var(--brand-b-600);
        --bg-brand-b-secondary: var(--brand-b-50);
        --bg-brand-b-tertiary: var(--brand-b-800);
        --bg-success: var(--system-green-100);
        --bg-success-secondary: var(--system-green-700);
        --bg-danger: var(--system-red-600);
        --bg-danger-secondary: var(--system-red-700);
    }

    /* 
        Dark mode.
        - Use dark:class to override the default theme.
    */
    :root[data-theme="dark"] {
        /* Text */
        --text-primary: var(--white);
        --text-primary-invert: var(--brand-c-900);
        --text-secondary: var(--gray-300);
        --text-brand: var(--brand-400);
        --text-success: var(--system-green-300);
        --text-danger: var(--system-red-500);

        /* Border */
        --border-primary: var(--gray-500);
        --border-secondary: var(--gray-600);
        --border-secondary-invert: var(--brand-c-300);
        --border-tertiary: var(--gray-200);
        --border-brand: var(--brand-400);

        /* Background */
        --bg-overlay: var(--brand-c-800-alpha);
        --bg-primary: var(--black);
        --bg-primary-invert: var(--white);
        --bg-secondary: var(--brand-c-800);
        --bg-tertiary: var(--gray-700);
        --bg-quaternary: var(--brand-c-900);
        --bg-brand-secondary: var(--brand-400);
        --bg-brand-tertiary: var(--brand-300);
        --bg-brand-b-primary: var(--brand-b-500);
        --bg-brand-b-secondary: var(--brand-b-800);
        --bg-success: var(--system-green-900);
        --bg-success-secondary: var(--system-green-600);
        --bg-danger: var(--system-red-400);
        --bg-danger-secondary: var(--system-red-300);
    }
}

@layer base {
    body {
        font-family: "Poppins", sans-serif;
    }

    /* Chrome autofill css background */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px var(--bg-primary) inset !important;
        -webkit-text-fill-color: var(--text-primary);
    }

    /* Base autofill state for inputs with faux border */
    input.shadow-faux-border:-webkit-autofill {
        -webkit-box-shadow:
            0 0 0 30px var(--bg-primary) inset,
            0 0 0 1px var(--border-primary) !important;
    }

    /* Base autofill state for inputs with error */
    input.shadow-faux-border-danger:-webkit-autofill {
        -webkit-box-shadow:
            0 0 0 30px var(--bg-primary) inset,
            0 0 0 1px var(--border-danger) !important;
    }

    /* Hover state - no focus ring */
    input.shadow-faux-border:-webkit-autofill:hover:not(:focus) {
        -webkit-box-shadow:
            0 0 0 30px var(--bg-primary) inset,
            0 0 0 1px var(--border-primary) !important;
    }

    /* Hover state with error - no focus ring */
    input.shadow-faux-border-danger:-webkit-autofill:hover:not(:focus) {
        -webkit-box-shadow:
            0 0 0 30px var(--bg-primary) inset,
            0 0 0 1px var(--border-danger) !important;
    }

    /* Focus state - with focus ring */
    input.shadow-faux-border:-webkit-autofill:focus {
        -webkit-box-shadow:
            0 0 0 30px var(--bg-primary) inset,
            0 0 0 1px var(--border-primary),
            0 0 0 4px rgba(0, 124, 131, 0.4) !important;
        @apply rounded-md outline-none;
    }

    /* Focus state with error - with focus ring */
    input.shadow-faux-border-danger:-webkit-autofill:focus {
        -webkit-box-shadow:
            0 0 0 30px var(--bg-primary) inset,
            0 0 0 1px var(--border-danger),
            0 0 0 4px rgba(0, 124, 131, 0.4) !important;
        @apply rounded-md outline-none;
    }

    .block-size {
        block-size: 1px;
    }

    /*  Headings */

    h1,
    .h1 {
        @apply text-5xl font-bold text-content-primary;
    }

    h2,
    .h2 {
        @apply text-3xl font-semibold text-content-primary;
    }

    h3,
    .h3 {
        @apply text-2xl font-normal text-content-primary;
    }

    h4,
    .h4 {
        @apply text-xl font-normal text-content-primary;
    }
    h5,
    .h5 {
        @apply text-lg font-normal text-content-primary;
    }

    .scroll-container-custom::-webkit-scrollbar {
        height: 3px;
        background: transparent; /* make it transparent */
    }

    /* Keep the track of the scrollbar transparent */
    .scroll-container-custom::-webkit-scrollbar-track {
        background: transparent; /* make it transparent */
    }

    /* Hide the scrollbar thumb by default */
    .scroll-container-custom::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    /* Show the scrollbar thumb on hover */
    .scroll-container-custom:hover::-webkit-scrollbar-thumb {
        background-color: var(--bg-primary-invert);
    }

    /* 
     * Floating Label Styles
        Float Label above Input when Input: 
        - has a value
        - is focused
        - placeholder is shown
        - is autofilled
     */

    .label--floating,
    input.input-value-true ~ .label--floating,
    input:-webkit-autofill ~ .label--floating,
    input.input-value-true:-webkit-autofill ~ .label--floating {
        @apply absolute
            left-2
            top-[5px]
            z-10
            origin-[0]
            -translate-y-4
            transform
            cursor-text
            select-none
            rounded-lg
            px-2
            text-xs
            !leading-4
            duration-100
            peer-placeholder-shown:top-1/2
            peer-placeholder-shown:-translate-y-1/2
            peer-placeholder-shown:scale-100
            peer-focus:left-2
            peer-focus:top-[5px]
            peer-focus:-translate-y-4
            peer-focus:rounded-lg
            peer-focus:bg-background-primary
            peer-focus:px-2
            peer-focus:text-xs;
    }

    /*  
        Using box-shadow instead of border as it doesn't impact element width/size.
        This is needed as the focus "border" (shadow) is greater than the default border.
     */

    /* Focus styles */
    .shadow-faux-border:focus,
    .shadow-faux-border:focus-within,
    button:focus,
    a:focus {
        position: relative;
        box-shadow: 0 0 0 4px rgba(0, 124, 131, 0.4); /* --brand-c-600, .4 alpha */
        @apply rounded-md outline-none;
    }

    /* Dev Mode Env */
    .dev-stripe {
        background: repeating-linear-gradient(45deg, var(--brand-c-400), var(--brand-c-400) 10px, var(--brand-c-500) 10px, var(--brand-c-500) 20px);
    }
    .dev-badge {
        @apply absolute cursor-pointer rounded-b px-1 text-xs drop-shadow focus:absolute;
        background-color: var(--brand-c-400);
        text-shadow: 1px 1px 1px var(--gray-300);
    }

    .blur-from-right {
        background: linear-gradient(270deg, #fff 84.62%, rgba(255, 255, 255, 0) 100%);
    }
    /* 
        Radix / ShadCN
    */

    /* 
        Set content width same as the trigger (button) that toggles the content 
        Ex: Combobox dropdown content will be the same width as the combobox trigger button
    */
    .width-equal-to-popover-trigger {
        width: var(--radix-popover-trigger-width);
    }

    /* Style Popover arrow */
    .popover-arrow {
        fill: white;
        filter: drop-shadow(0 0 1px gray);
        clip-path: inset(0 -10px -10px -10px);
    }

    .PopoverContent {
        animation-duration: 400ms;
        animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        will-change: transform, opacity;
    }
    .PopoverContent[data-state="open"][data-side="top"] {
        animation-name: slideDownAndFade;
    }
    .PopoverContent[data-state="open"][data-side="bottom"] {
        animation-name: slideUpAndFade;
    }

    @keyframes slideDownAndFade {
        from {
            opacity: 0;
            transform: translateY(-2px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
    @keyframes slideUpAndFade {
        from {
            opacity: 0;
            transform: translateY(2px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
}
